<template>
  <PhysioForm></PhysioForm>
</template>

<script>
import PhysioForm from "@/components/physio-form.vue";
export default {
  components: { PhysioForm },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style lang="css" scoped></style>
