import { RequestService } from "@/api/instance";
import { DeAuthSession } from "@/api/de-auth";

export const SendForm = async enquiry => {
  try {
    await RequestService().post("/enquiry", { enquiry });
  } catch (e) {
    DeAuthSession(e);

    throw e;
  }
};
