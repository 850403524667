<template>
  <div class="container">
    <div v-show="!formSubmitted">
      <h1 class="enquiry-title">Tell us about your needs</h1>
      <div class="tooltip-container">
        <v-icon color="primary" medium>info</v-icon>
        <p class="tooltip-text">
          If you are submitting this on behalf of someone else, please note the
          details on this form should relate to the person who will actually be
          receiving the care.
        </p>
      </div>
    </div>
    <v-form ref="form" v-show="!formSubmitted">
      <v-stepper
        alt-labels
        class="elevation-0"
        style="background: none;"
        v-model="stepCount"
      >
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            editable
            step="1"
            :complete="stepCount > 1"
            edit-icon="check"
          >
            About you
          </v-stepper-step>
          <v-divider :class="{ 'active-divider': stepCount > 1 }"></v-divider>
          <v-stepper-step
            editable
            step="2"
            :complete="stepCount > 2"
            edit-icon="check"
          >
            About your physiotherapy
          </v-stepper-step>
          <v-divider :class="{ 'active-divider': stepCount > 2 }"></v-divider>
          <v-stepper-step
            editable
            step="3"
            :complete="stepCount > 3"
            edit-icon="check"
          >
            Submit
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <label>
              <p class="input-label">Age <span>*</span></p>
              <p class="input-hint">- Must be in years</p>
              <v-text-field
                style="max-width: 250px"
                name="age"
                type="number"
                outlined
                :rules="[rules.required]"
                v-model="about.age"
              ></v-text-field>
            </label>
            <p class="input-label">
              Would you prefer physiotherapy in a clinic or at your home?
              <span>*</span>
            </p>
            <v-radio-group
              :rules="[rules.required]"
              v-model="physioAnswers.location"
              row
            >
              <v-radio label="Home" value="Home"></v-radio>
              <v-radio label="Clinic" value="Clinic"></v-radio>
            </v-radio-group>
            <label v-if="physioAnswers.location === 'Clinic'">
              <p class="input-label">
                Where is the most convenient location for you to have
                physiotherapy <span>*</span>
              </p>
              <p class="input-hint">
                e.g. ‘near Paddington’, ‘Ealing’ etc
              </p>
              <v-text-field
                name="locationPreference"
                outlined
                v-model="physioAnswers.location_preference"
                :rules="[rules.required]"
              ></v-text-field>
            </label>

            <label v-if="physioAnswers.location === 'Home'">
              <p class="input-label">Type of accommodation <span>*</span></p>
              <p class="input-hint">e.g. house, 3rd floor flat, hotel etc</p>
              <v-text-field
                name="accommodation"
                outlined
                :rules="[rules.required]"
                v-model="about.accommodation"
              ></v-text-field>
            </label>
            <p class="input-label">
              Do you have any mobility restrictions? <span>*</span>
            </p>
            <v-radio-group
              :rules="[rules.required]"
              v-model="about.mobility"
              row
            >
              <v-radio label="Yes" value="Yes"></v-radio>
              <v-radio label="No" value="No"></v-radio>
            </v-radio-group>
            <label v-if="about.mobility === 'Yes'">
              <p class="input-label">
                Please expand on your previous selection <span>*</span>
              </p>
              <v-text-field
                name="mobilityExpanded"
                outlined
                v-model="about.mobilityExpanded"
                :rules="[rules.required]"
              ></v-text-field>
            </label>
            <v-btn class="btn-right" color="primary" @click="next()"
              >Next</v-btn
            >
          </v-stepper-content>
          <v-stepper-content step="2">
            <label>
              <p class="input-label">
                In a few words, why do you need physiotherapy? <span>*</span>
              </p>
              <p class="input-hint">
                e.g. to improve my mobility after shoulder surgery, treatment
                for back pain etc
              </p>
              <v-textarea
                name="physio-description"
                outlined
                auto-grow
                rows="6"
                row-height="15"
                v-model="physioAnswers.description"
                :rules="[rules.required]"
              ></v-textarea>
            </label>
            <label>
              <p class="input-label">
                What date would you like your physiotherapy to start?
                <span>*</span>
              </p>
              <p class="input-hint">
                Please click on a date in the calendar below
              </p>
              <v-date-picker
                v-model="physioAnswers.date"
                reactive
                full-width
                elevation="5"
                color="primary"
                class="datePicker"
                :min="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </label>
            <label>
              <p class="input-label">
                When would you like your physiotherapy to take place?
                <span>*</span>
              </p>
              <v-select
                name="physioTime"
                :items="physioTimeOptions"
                label="Select an option"
                outlined
                v-model="physioAnswers.time"
                :rules="[rules.required]"
                :menu-props="{ maxHeight: 500 }"
              ></v-select>
            </label>
            <label v-if="physioAnswers.time === 'Other (please state)'">
              <p class="input-label">
                Please state when you would like your physiotherapy to take
                place <span>*</span>
              </p>
              <v-text-field
                name="physioTime"
                outlined
                v-model="physioAnswers.timeExpanded"
                :rules="[rules.required]"
              ></v-text-field>
            </label>
            <label>
              <p class="input-label">
                How are you paying for your physiotherapy? <span>*</span>
              </p>
              <v-select
                name="payment"
                :items="physioPaymentOptions"
                label="Select an option"
                outlined
                v-model="physioAnswers.payment"
                :rules="[rules.required]"
                :menu-props="{ maxHeight: 500 }"
              ></v-select>
            </label>
            <label v-if="paymentExtended">
              <p
                class="input-label"
                v-if="physioAnswers.payment === 'Other (please state)'"
              >
                Please expand upon your payment method <span>*</span>
              </p>
              <p
                class="input-label"
                v-if="physioAnswers.payment === 'Through private insurance'"
              >
                Please state the name of your insurance company <span>*</span>
              </p>
              <v-text-field
                name="paymentExtended"
                outlined
                v-model="physioAnswers.insurer"
                :rules="[rules.required]"
              ></v-text-field>
            </label>
            <div class="btn-container">
              <v-btn color="lightGrey" @click="back()">Back</v-btn>
              <v-btn color="primary" @click="next()">Next</v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content
            step="3"
            v-if="this.$route.path === '/auth/register'"
          >
            <p class="input-label">
              Username
              <span>*</span>
            </p>
            <p class="input-hint">
              - Your username will be what we display to companies when
              presenting your request, please choose something you are happy
              being displayed
            </p>
            <v-text-field
              name="username"
              v-model="user.client.username"
              outlined
              :rules="[rules.required]"
            ></v-text-field>

            <div class="split-input-container">
              <div>
                <p class="input-label">
                  First Name
                  <span>*</span>
                </p>
                <v-text-field
                  name="first_name"
                  v-model="user.client.first_name"
                  outlined
                  :rules="[rules.required]"
                ></v-text-field>
              </div>
              <div>
                <p class="input-label">
                  Last Name
                  <span>*</span>
                </p>
                <v-text-field
                  name="last_name"
                  v-model="user.client.last_name"
                  outlined
                  :rules="[rules.required]"
                ></v-text-field>
              </div>
            </div>

            <p class="input-label">
              Email
              <span>*</span>
            </p>
            <v-text-field
              name="email"
              v-model="user.email"
              outlined
              :rules="[rules.required, rules.email]"
            ></v-text-field>

            <p class="input-label">
              Password
              <span>*</span>
            </p>
            <p class="input-hint">- Must be at least 8 characters</p>
            <v-text-field
              name="password"
              v-model="user.password"
              type="password"
              outlined
              :rules="[rules.required, rules.password]"
            ></v-text-field>

            <p class="input-label">
              Postcode
              <span>*</span>
            </p>
            <p class="input-hint">
              - Must be the postcode of the person requiring care
            </p>
            <v-text-field
              name="postcode"
              v-model="user.client.postcode"
              outlined
              :rules="[rules.required, rules.postcode]"
            ></v-text-field>

            <p class="input-label">
              Contact Telephone Number
              <span>*</span>
            </p>
            <v-text-field
              name="tel"
              v-model="user.client.tel"
              type="tel"
              outlined
              :rules="[rules.required]"
            ></v-text-field>

            <label>
              <p class="input-label">
                How did you hear about PhysioCompare?
              </p>
              <v-select
                name="lead"
                :items="leadOptions"
                v-model="user.client.lead"
                label="Select an option"
                outlined
                :menu-props="{ maxHeight: 400, overflowY: false }"
              ></v-select>
            </label>
            <label v-if="user.client.lead === 'Other (please state)'">
              <p class="input-label">
                Please expand on your previous selection
              </p>
              <v-text-field
                name="leadExpanded"
                outlined
                v-model="user.client.leadExpanded"
              ></v-text-field>
            </label>

            <div class="auth-container" v-show="display_2fa">
              <v-switch
                @change="mfaEnable = !mfaEnable"
                label="Enable Two-Factor Authentication?"
                hide-details="auto"
              ></v-switch>

              <v-btn icon @click="show_2fa_info = !show_2fa_info">
                <v-icon>mdi-help-circle</v-icon>
              </v-btn>
            </div>
            <v-expand-transition>
              <div v-show="show_2fa_info">
                <v-divider></v-divider>
                <v-card-title style="word-break: break-word"
                  >What is Two-Factor Authentication?</v-card-title
                >
                <v-card-text>
                  Two Factor Authentication, or 2FA, is an extra layer of
                  protection used to ensure the security of your account beyond
                  just your username and password. If you would like to read
                  more about it you can do so here:
                </v-card-text>
                <div
                  style="display:flex; justify-content:center;align-items:center;"
                >
                  <v-btn
                    large
                    href="https://authy.com/what-is-2fa/"
                    style="margin-bottom: 16px;"
                    >Authy - What Is 2FA?</v-btn
                  >
                </div>
              </div>
            </v-expand-transition>
            <v-expand-transition>
              <div v-show="mfaEnable">
                <v-divider></v-divider>
                <v-card-title style="word-break: break-word"
                  >Authentication method:</v-card-title
                >
                <v-card-text>
                  <v-radio-group v-model="authChoice">
                    <v-radio label="SMS" value="sms"></v-radio>
                    <v-radio label="Authy App" value="app"></v-radio>
                    <v-radio label="Email" value="email"></v-radio>
                    <v-radio label="Telephone" value="tel"></v-radio>
                  </v-radio-group>
                </v-card-text>
              </div>
            </v-expand-transition>
            <v-divider v-show="display_2fa"></v-divider>
            <v-checkbox
              v-model="physioAnswers.mobilityConsent"
              :rules="[rules.required]"
              hide-details
              class="tc-box"
            >
              <template v-slot:label>
                <p class="label">
                  I consent to PhysioCompare collecting information about my
                  mobility restrictions and passing this on to physiotherapy
                  companies to help me find the services I need.
                </p>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="physioAnswers.informationConsent"
              :rules="[rules.required]"
              hide-details
              class="tc-box"
            >
              <template v-slot:label>
                <p class="label">
                  I consent to PhysioCompare collecting information about why I
                  need Physiotherapy and passing this on to physiotherapy
                  companies to help me find the services I need.
                </p>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="user.terms"
              required
              :rules="[rules.required]"
              hide-details
              class="tc-box"
            >
              <p slot="label" class="label">
                Please tick here to confirm you agree to our
                <a @click.stop="viewTermsAndConditions = true"
                  >Terms and Conditions</a
                >
                and have read our
                <a @click.stop="viewPrivacyPolicy = true">Privacy Notice</a>
                that contains information about how we use your personal data
                and your rights.
              </p>
            </v-checkbox>

            <div class="btn-container">
              <v-btn color="lightGrey" @click="back()">Back</v-btn>
              <v-btn color="primary" @click="register()">Submit</v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-content step="3" v-else>
            <h2>
              Thank you for providing information on the physiotherapy you need.
            </h2>
            <p class="label" style="margin-left: 0">
              In order to collect and pass on your information to physio
              companies to help you find the best match, we need to have your
              explicit consent. We do not pass this information on to third
              parties.
            </p>

            <v-checkbox
              v-model="physioAnswers.mobilityConsent"
              :rules="[rules.required]"
              hide-details
              class="tc-box"
            >
              <template v-slot:label>
                <p class="label">
                  I consent to PhysioCompare collecting information about my
                  mobility restrictions and passing this on to physiotherapy
                  companies to help me find the services I need.
                </p>
              </template>
            </v-checkbox>

            <v-checkbox
              v-model="physioAnswers.informationConsent"
              :rules="[rules.required]"
              hide-details
              class="tc-box"
            >
              <template v-slot:label>
                <p class="label">
                  I consent to PhysioCompare collecting information about why I
                  need physiotherapy and passing this on to physiotherapy
                  companies to help me find the services I need.
                </p>
              </template>
            </v-checkbox>
            <div class="btn-container">
              <v-btn color="lightGrey" @click="back()">Back</v-btn>
              <v-btn color="primary" @click="submit()">Submit</v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-form>
    <div v-show="registrationComplete" class="completed">
      <h1 class="complete-title">Registration complete!</h1>
      <p id="sub-title">
        Thank you for providing information on the physiotherapy you need - this
        has been sent to the physio companies.
      </p>
      <h2 class="header">What's next?</h2>
      <p>
        Physio companies will reply within one working day if they are able to
        meet your needs.
      </p>
      <p>
        An email will now be sent to you with an activation link. (Please note
        this may take a few minutes.)
      </p>
      <p>
        If you do not receive the email please check your spam/junk folder.
      </p>
      <p>
        <b>
          In order to view your responses you must confirm your email and login.
        </b>
      </p>
      <p>
        If you experience any issues please contact our help desk using the icon
        in the bottom right of your screen.
      </p>
    </div>
    <div v-show="enquirySubmitted" class="completed">
      <h1 class="complete-title">Physio request submitted!</h1>
      <p id="sub-title">
        Thank you for providing information on the physio you need - this has
        been sent to the physio companies.
      </p>
      <h2 class="header">What's next?</h2>
      <p>
        Physio companies will reply within one working day if they are able to
        meet your needs.
      </p>
      <p>
        Please see the request page using the link below to view your submitted
        request. Click on your request to see company responses.
      </p>
      <v-btn color="primary" @click="open()">View your requests</v-btn>
      <p id="help-text">
        If you experience any issues please contact our help desk using the icon
        in the bottom right of your screen.
      </p>
    </div>
    <v-dialog v-model="viewTermsAndConditions" max-width="1024px">
      <v-card>
        <v-card-text class="pa-5">
          <object style="width:100%; height:400px;" :data="termsURL"></object>
        </v-card-text>
        <v-card-actions>
          <v-btn color="lightGrey" @click.stop="viewTermsAndConditions = false"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" target="_blank" :href="termsURL"
            >View PDF</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="viewPrivacyPolicy" max-width="1024px">
      <v-card>
        <v-card-text class="pa-5">
          <object style="width:100%; height:400px;" :data="privacyURL"></object>
        </v-card-text>
        <v-card-actions>
          <v-btn color="lightGrey" @click.stop="viewPrivacyPolicy = false"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" target="_blank" :href="privacyURL"
            >View PDF</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SendForm } from "@/api/requests/form";
import { Register } from "@/api/auth/register";
import { SITE, MFA_ENABLED } from "@/config";
import EventBus from "@/event-bus";
export default {
  data() {
    return {
      stepCount: 1,
      careAnswers: { activities: [] },
      physioAnswers: {},
      about: {},
      rules: {
        required: value => !!value || "Required",
        email: value => {
          const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return pattern.test(value) || "Invalid email";
        },
        password: value => value.length >= 8 || "Password is too short",
        postcode: value => {
          const pattern = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g;
          return pattern.test(value) || "Invalid postcode ";
        }
      },
      physioLocationOptions: ["At my home", "At the physiotherapist's clinic"],
      physioPaymentOptions: [
        "Through private insurance",
        "By myself",
        "Other (please state)"
      ],
      physioTimeOptions: [
        "Morning",
        "Afternoon",
        "Evening",
        "Not sure at the moment",
        "Other (please state)"
      ],
      leadOptions: [
        "Word of mouth",
        "Digital advertising",
        "leaflet",
        "Other (please state)"
      ],
      user: {
        email: "",
        password: "",
        userType: "CLIENT",
        client: { postcode: "" }
      },
      viewTermsAndConditions: false,
      termsURL: "/assets/physioCompare_terms.pdf",
      viewPrivacyPolicy: false,
      privacyURL: "/assets/physioCompare_privacy.pdf",
      show_2fa_info: false,
      mfaEnable: false,
      display_2fa: MFA_ENABLED,
      authChoice: null,
      registrationComplete: false,
      enquirySubmitted: false
    };
  },
  computed: {
    paymentExtended: function() {
      if (
        this.physioAnswers.payment === "Other (please state)" ||
        this.physioAnswers.payment === "Through private insurance"
      ) {
        return true;
      }
      return false;
    },
    formSubmitted: function() {
      if (this.registrationComplete || this.enquirySubmitted) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async register() {
      if (this.$refs.form.validate()) {
        this.user.privacy = this.user.terms;
        try {
          this.user.site = SITE;
          if (this.user.client.lead === "Other (please state)") {
            this.user.client.lead = this.user.client.leadExpanded;
          }
          let data = this.preparePhysioData(this.prepareData());
          await Register({
            user: this.user,
            mfaEnable: this.mfaEnable,
            authChoice: this.authChoice,
            enquiry: data
          });
          EventBus.$emit("toast", { text: "Account Created", type: "success" });
          this.registrationComplete = true;
        } catch (err) {
          this.registrationComplete = false;
          throw err;
        }
      }
    },

    async submit() {
      if (this.$refs.form.validate()) {
        try {
          let data = this.preparePhysioData(this.prepareData());
          if (data != null) {
            await SendForm(data);
          }
          this.enquirySubmitted = true;
        } catch (err) {
          this.enquirySubmitted = false;
          throw err;
        }
      }
    },
    prepareData() {
      this.about.age = parseInt(this.about.age);
      if (this.about.mobility === "Yes") {
        this.about.mobility = this.about.mobilityExpanded;
      }
      return {
        about: this.about
      };
    },
    preparePhysioData(query) {
      let physioAnswers = this.physioAnswers;
      if (physioAnswers.insurer != null && physioAnswers.insurer.length > 0) {
        physioAnswers.payment = physioAnswers.insurer;
        delete physioAnswers.insurer;
      }
      if (physioAnswers.typeKnow === "No") {
        physioAnswers.type = "Not Known";
      }
      delete physioAnswers.typeKnow;
      if (
        physioAnswers.timeExpanded != null &&
        physioAnswers.timeExpanded.length
      ) {
        physioAnswers.time = physioAnswers.timeExpanded;
        delete physioAnswers.timeExpanded;
      }
      query.physio = physioAnswers;
      if (physioAnswers.date != null) {
        query.physio.date = new Date(physioAnswers.date);
      } else {
        return;
      }
      return query;
    },
    open() {
      this.$router.push("/home/requests");
    },
    back() {
      this.stepCount--;
      this.goTop();
    },
    next() {
      this.stepCount++;
      this.goTop();
    },
    goTop() {
      // Timeout required to give the stepper time to
      // adjust before forcing the user view to the top.
      setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 100);
    }
  }
};
</script>

<style lang="css" scoped>
.container {
  width: 95%;
  margin: 15px auto;
  max-width: 1185px;
}
.enquiry-title {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  color: var(--v-grey-base);
}
.tooltip-container {
  display: flex;
  justify-content: center;
}
.tooltip-text {
  font-size: 1em;
  align-self: center;
  margin: 0 0 0 5px;
  color: var(--v-grey-lighten1);
}
::v-deep .v-icon.v-icon {
  font-size: 36px;
}
::v-deep .v-stepper {
  margin-top: 50px;
}
::v-deep .v-stepper__label {
  text-align: center;
  display: block !important;
  font-size: 20px;
  color: var(--v-grey-base) !important;
}
::v-deep .v-stepper__step__step {
  height: 48px;
  width: 48px;
  font-size: 1.5em;
}
::v-deep .v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 48px -56px 0;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.active-divider {
  border: var(--v-primary-base) 1px solid !important;
}
::v-deep .v-stepper__step__step .v-icon.v-icon {
  font-size: 1.7rem;
}
.input-label {
  font-size: 22px;
  margin: 0;
  color: var(--v-grey-base);
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}
.input-hint {
  color: var(--v-grey-lighten1);
}
::v-deep .v-input__slot {
  margin-top: 10px;
}
::v-deep .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
  align-items: center;
}
::v-deep .v-radio {
  padding: 10px;
  min-width: 140px;
}
::v-deep .v-radio > .v-label {
  font-size: 1.2rem;
  margin-left: 15px;
}
.btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 5px;
}
.btn-right {
  float: right;
  margin-bottom: 10px;
}
.datePicker {
  width: 98%;
  margin: 0 auto 30px auto;
}
.split-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.split-input-container > div {
  width: 49%;
}
::v-deep .v-list-item__title {
  font-size: 1.3rem;
}
.label {
  font-size: 1.4rem;
  margin: 16px;
}
.label a {
  color: #0000ff;
}
.tc-box {
  margin-bottom: 32px;
}
::v-deep .v-input--selection-controls__input {
  margin: 0 8px;
}
.completed {
  text-align: center;
  padding: 0;
}
.complete-title {
  font-size: 32px;
  margin-bottom: 15px;
  margin-top: 25px;
}
#sub-title {
  margin-bottom: 50px;
}
.header {
  font-size: 32px;
  margin-bottom: 15px;
}
p {
  font-size: 20px;
}
#help-text {
  margin-top: 15px;
}
.auth-container {
  display: flex;
  margin: 10px 0 30px 0;
}
.auth-container ::v-deep .v-input__slot {
  background-color: #f8f8f8 !important;
}
@media only screen and (max-width: 767px) {
  ::v-deep .v-stepper__content {
    padding: 0;
  }
  ::v-deep .v-stepper__wrapper {
    padding-bottom: 60px;
  }
  .container {
    width: 100%;
    margin: 5px auto;
  }
  ::v-deep .v-icon.v-icon {
    font-size: 28px;
  }
  ::v-deep .v-stepper__label {
    display: none !important;
  }
  ::v-deep .v-stepper__step--active .v-stepper__label {
    display: block !important;
  }
  ::v-deep .v-stepper__step__step {
    height: 24px;
    width: 24px;
    font-size: 0.75em;
  }
  ::v-deep .v-stepper__step {
    flex-basis: auto;
    max-width: 150px;
  }
  ::v-deep .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 35px -20px 0;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  ::v-deep .v-stepper__step__step .v-icon.v-icon {
    font-size: 1.2rem;
  }
  .btn-container {
    justify-content: space-between;
  }
  .name-container {
    flex-direction: column;
  }
  #first-name {
    width: 100%;
  }
  #last-name {
    width: 100%;
  }
  .label {
    margin: 16px 0;
    font-size: 1rem;
  }
}
</style>
